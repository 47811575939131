.fadeIn {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.survey {
  background-color: #f7f7f7;
  padding: 0 1rem;
  height: 100%;
}
.title {
  font-weight: 600;
  font-size: 1rem;
  margin: 1rem 0;
  text-align: center;
}
.slide {
  background-color: #fff;
  font-size: 1rem;
  padding: 1px 1.5rem 1px 1.5rem;
  line-height: 1.2;
  font-weight: normal;
  border-radius: 10px;
  box-shadow: 0 3px 6px #00000025;
  margin: 0.5rem;
  height: 350px;
  overflow: auto;
  position: relative;
  letter-spacing: normal;
  font-weight: 500;
}
.questionTitle {
  padding-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}
.progressButtons {
  display: flex;
  column-gap: 1rem;
}
.progressIndicatorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}
.progressTrack {
  width: 100%;
  background-color: #e2e2e2;
  height: 10px;
  border-radius: 20px;
  position: relative;
}
.progressIndicator {
  height: 10px;
  background-color: #208c91;
  border-radius: 20px;
  position: absolute;
}

.percentageText {
  font-size: 0.8rem;
  font-weight: normal;
  margin-bottom: 0.5rem;
}
