body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root {
  min-height: 100%;
  height: 100%;
  overflow: hidden;
}

.MuiButton-root {
  min-width: 0 !important;
  font-family: 'Montserrat';
  font-weight: 600 !important;
  letter-spacing: 0 !important;
}

.MuiFormControlLabel-label {
  font-family: 'Montserrat' !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #000 !important;
  font-family: 'Montserrat';
}

.MuiFormHelperText-root.Mui-error {
  color: #f44336 !important;
  font-family: 'Montserrat';
}
.MuiFormHelperText-root.Mui-focused.Mui-error label {
  color: #f44336 !important;
  font-family: 'Montserrat';
}

.MuiListItemIcon-root {
  min-width: 30px !important;
}

.MuiListItemIcon-root svg {
  width: 20px;
  height: 20px;
}
.MuiInputLabel-formControl {
  font-family: 'Montserrat' !important;
  font-size: 16px !important;
}

/* Target the input inside every Select */
.MuiSelect-root {
  font-family: 'Montserrat' !important;
  font-size: 14px;
}

/* Target the label inside every MenuItem */
.MuiMenuItem-root {
  font-family: 'Montserrat' !important;
  font-size: 14px;
}

.MuiTabs-scroller {
  min-height: 36px !important;
  height: 36px !important;
}

.MuiInputBase-input,
.MuiAlert-message {
  font-family: 'Montserrat' !important;
}

.MuiAlert-filledError {
  background-color: #c92929 !important;
}
.MuiPaper-rounded {
  border-radius: 8px !important;
}
.MuiTableHead-root * button {
  color: #888 !important;
  text-transform: uppercase !important;
  font-weight: 500 !important;
  font-family: 'Montserrat' !important;
  font-size: 14px !important;
}
.MuiTableHead-root * button:first-child {
  padding-left: 0 !important;
}
.MuiChip-root {
  background-color: #ebebeb !important;
}
.MuiChip-label {
  font-family: 'Montserrat' !important;
  font-size: 12px !important;
  font-weight: 600;
}
.Mui-disabled {
  opacity: 0.5;
}
.MuiSwitch-thumb {
  color: #30feaf !important;
}
.Mui-focused:after,
.Mui-focused:hover {
  border-bottom-color: #30feaf !important;
}
textarea.Mui-focused:after,
textarea.Mui-focused:hover {
  border-bottom-color: transparent !important;
}

.MuiAlert-filledSuccess {
  color: #000 !important;
  background-color: #30feaf !important;
  border-radius: 6px !important;
}
