.panel-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  height: 100%;
  overflow: hidden;
  align-items: start;
}

.menu {
  grid-column: 1 / 1;
  grid-row: 1;
  transition: transform 0.2s ease-in-out;
  transform: translateX(0);
  align-self: start;
}
.menu-item {
  background-color: #fbfbfb;
  padding: 1rem;
  font-size: 14px;
  margin-bottom: 0.25rem;
  border-radius: 6px;
  color: #7a7a7a;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #0000001f;
  cursor: pointer;

  &:hover {
    background-color: #eaeaea;
    transition: 0.1s ease-in-out;
  }
}
.back-button {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  background: #fbfbfb;
  width: fit-content;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  border-radius: 9px;
  font-size: 12px;
  margin-bottom: 1rem;
  color: #7a7a7a;
  border: 1px solid #0000001f;
  transition: 0.1s ease-in-out;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: #eaeaea;
    transition: 0.1s ease-in-out;
  }
  svg {
    width: 18px;
  }
}
.panel-content {
  grid-column: 1 / 1;
  grid-row: 1;
  transition: transform 0.2s ease-in-out;
  transform: translateX(100%);
  align-self: start;
  height: 100%;
}

.slide-out {
  transform: translateX(-100%);
}

.slide-in {
  transform: translateX(0);
}
.section_header {
  font-weight: 600;
  font-size: 12px;
  color: #939393;
  margin: 2rem 0 1rem 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}
