.customNavLink {
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-transform: capitalize;
  font-size: 15px !important;
}

.listItemText {
  font-size: 14px;
  font-weight: 700;
}

.navLink {
  padding: 0.5em 1em !important;
  font-weight: normal;
  cursor: pointer;
  user-select: none;
  display: flex;
  color: #fff;
  font-size: 15px;
  text-decoration: none;
  height: 30px;
  font-weight: 600;
  text-transform: capitalize;
  border-bottom: 3px solid transparent;
}
.navLink .active {
  color: #30feaf;
  border-bottom: 3px solid #30feaf;
}

.navLink:hover .listItemText {
  background: yellow;
}
