.app-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 24px;
  font-weight: 600;
  background: #61eea7;
  color: #333;
}
